import { createStore } from 'redux'
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

type state = {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  modalLogout: boolean
  theme: string
  role: boolean
  dataAddOrder: {
    id: string
    date_start: string
    userResponsible: string
  }
  dataUser: {
    id: string
    hash_telegraf: string
    telegramUserId: string
  }
  order: string
  company: {
    id: number
    name: string
    telephone: string
    legalAddress: string
    fullNameOfDirector: string
  }
}

const initialState: state = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  modalLogout: false,
  theme: 'default',
  role: false,
  dataAddOrder: {
    id: '',
    date_start: '',
    userResponsible: '',
  },
  dataUser: {
    id: '',
    hash_telegraf: '',
    telegramUserId: '',
  },
  order: '',
  company: {
    id: 0,
    name: '',
    telephone: '',
    legalAddress: '',
    fullNameOfDirector: '',
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type args = { type?: string; [key: string]: any }

const reducer = (state = initialState, { type, ...rest }: args) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
})
export default store

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<state> = useSelector
