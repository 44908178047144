import React, { Component, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import AuthApi from './views/auth/auth.api'
// Containers
import { useDispatch } from 'react-redux'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/auth/Login'))
/* const Users = React.lazy(() => import('./views/users/Users'))
const User = React.lazy(() => import('./views/users/User')) */
const Dashboard = React.lazy(() => import('./views/dashboard/dashboard'))
const Objects = React.lazy(() => import('./views/objects/objects'))
const Object = React.lazy(() => import('./views/objects/object'))
const Orders = React.lazy(() => import('./views/orders/Orders'))
const NewObject = React.lazy(() => import('./views/objects/NewObject'))
const EditObject = React.lazy(() => import('./views/objects/EditObject'))

const App = (): JSX.Element => {
  const dispatch = useDispatch()

  function getRole() {
    AuthApi.getMe()
      .then((result) => {
        return AuthApi.getRole(result.data.id)
      })
      .then((res) => {
        //"LabEmployee" kalau true berarti lab kalau false berarrti zakascik
        //если true то лаб если false то закаччик

        dispatch({
          type: 'set',
          dataUser: {
            id: res?.data?.id,
            hash_telegraf: res?.data?.hash_telegraf,
            telegramUserId: res?.data?.telegramUserId,
          },
        })
        dispatch({ type: 'set', role: res?.data?.role?.name === 'LabEmployee' })
        return AuthApi.getCompanyOfUser(res?.data?.id)
      })
      .then((resp) => {
        const getDataCompany = resp?.data?.company

        if (getDataCompany) {
          dispatch({
            type: 'set',
            company: {
              id: getDataCompany.id,
              name: getDataCompany.name,
              telephone: getDataCompany.telephone,
              legalAddress: getDataCompany.legalAddress,
              fullNameOfDirector: getDataCompany.fullNameOfDirector,
            },
          })
        }
      })
  }

  function RequireAuth({ children }: any) {
    getRole()
    if (!localStorage.access_token) {
      return <Navigate to="/login" />
    }
    return children
  }

  return (
    <Router>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <DefaultLayout />
              </RequireAuth>
            }
          >
            <Route
              path="*"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/objects"
              element={
                <RequireAuth>
                  <Objects />
                </RequireAuth>
              }
            />
            <Route
              path="/objects/:id"
              element={
                <RequireAuth>
                  <Object />
                </RequireAuth>
              }
            />
            <Route
              path="/orders"
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />
            <Route
              path="/new-object"
              element={
                <RequireAuth>
                  <NewObject />
                </RequireAuth>
              }
            />
            <Route
              path="/edit-object"
              element={
                <RequireAuth>
                  <EditObject />
                </RequireAuth>
              }
            />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
