import api from '../../api'
import { UserLoginResult } from '../../typings'

export default class AuthApi {
  static async login(login: string, password: string) {
    return api.post('/auth/local', {
      identifier: login,
      password: password,
    })
  }
  static async recovery(email: string) {
    return api.post('/auth/forgot-password', {
      email: email,
    })
  }
  static async getMe() {
    return api.get('/users/me')
  }
  static async getUsers() {
    return api.get('/users')
  }
  static async getRole(id: any) {
    return api.get(`/users/${id}?populate=role`)
  }
  static async getCompanyOfUser(id: any) {
    return api.get(`/users/${id}?populate=company`)
  }
}
